<template>
  <div class="flex justify-between items-center border-2 p-4 mt-2">
    <div class="flex items-center">
      <input
        class="cursor-pointer"
        type="radio"
        :name="planName"
        :checked="isSelected"
        @click="selectPlanHandler"
      />
      <div class="ml-5">
        <p class="font-semibold">{{ planLabel }}</p>
        <p class="text-sm">Per player</p>
      </div>
    </div>
    <div class="border-l-2 pl-2 text-sm text-gray-500">
      <p>Sub-total</p>
      <em v-if="loading" class="fa fa-spinner fa-spin ml-5"></em>
      <p v-else>USD {{ planPrice.toFixed(2) ?? 0 }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  planName: {
    type: String,
    required: true,
  },
  planLabel: {
    type: String,
    required: true,
  },
  planPrice: {
    type: Number,
    default: 0,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["selectPlan"]);

function selectPlanHandler() {
  emit("selectPlan", props.planName);
}
</script>

<style scoped>
.border-2 {
  border-width: 2px;
}
</style>
